import React, { Component } from 'react';
import Axios from 'axios';
import ErrorHandler from './ErrorHandler';
import Services from './Services';
import AutoPostcode from './AutoPostcode';
import { Button, Collapse } from 'reactstrap';
import placeholder from '../assets/images/placeholder/75.png';

class Search extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			service: 'Conveyancing',
			postcode: '',
			radius: '',
			items: []
		}
	}

	getLocation = (value) => {
		this.setState({
			postcode: value.toUpperCase(),
		})
	}

	getResults = () => {
		Axios.get( process.env.REACT_APP_APISRV + 'getpropertylawyerquotes?postcode=' + this.state.postcode + '&radius=' + this.state.radius )
		.then(
			response => {
				this.setState({
					isLoaded: true,
					loading: false,
					items: response.data
				});
			},
			error => {
				this.setState({
					isLoaded: true,
					loading: false,
					error
				});
			}
		)
	}

	handleClick = () => {
		this.setState({
			radius: this.radius.value,			
			loading: true,
			isLoaded: false
		}, () => {
			if ( (this.state.radius) && (this.state.postcode) ) {
				this.getResults();
			}
		})
	}

	render() {
		return (
			<div className="mt-5">
				<div className="container">
				
					<ResultsTitle data={this.state} />
					<div className="row">
						<div className="col-lg-4">
							<div className="form-group">
								<Services />
							</div>
						</div>
						<div className="col-lg-3">
							<div className="form-group">
								<AutoPostcode locationInput={this.getLocation} />
							</div>
						</div>
						<div className="col-lg-3">
							<div className="form-group">
								<input className="form-control" type="number" name="Radius" placeholder="Radius" defaultValue="5" ref={input => this.radius = input} />
							</div>
						</div>
						<div className="col-lg-2">
							<Button color="primary" onClick={this.handleClick} block>Search</Button>
						</div>
					</div>
				</div>
				<Results data={this.state} />
			</div>
		)
	}
}

const ResultsTitle = (props) => {

	if (props.data.service && props.data.radius && props.data.postcode && props.data.isLoaded) {
		return (
			<h2>{props.data.items.length} {props.data.service} Solicitors within {props.data.radius} miles of {props.data.postcode}</h2>
		)
	} else if (props.data.loading) {
		return <h2>Loading results...</h2>	
	}

	return (
		<h2>Search now</h2>
	)

}

const ResultsLoader = (props) => {
	
	if (!props.data.isLoaded && props.data.loading) {
		return <i className={`far fa-circle-notch fa-spin text-${props.color} fa-${props.size}x`}></i>
	}

	return <i className={`far fa-check-circle text-success text-${props.color} fa-${props.size}x`}></i>;
}


// const filters = () => {
// 	return (
// 		<div className="bg-dark text-white py-3 container-fluid">
// 			<div className="row text-center">
// 				<div className="col-md">Law firm <i className="fal fa-fw fa-chevron-down"></i></div>
// 				<div className="col-md">Rating <i className="fal fa-fw fa-chevron-down"></i></div>
// 				<div className="col-md">Regulated <i className="fal fa-fw fa-chevron-down"></i></div>
// 				<div className="col-md">Distance <i className="fal fa-fw fa-chevron-down"></i></div>
// 				<div className="col-md">Legal fee price <i className="fal fa-fw fa-chevron-down"></i></div>
// 			</div>
// 		</div>
// 	)
// }


const Results = props => {

	const items = props.data.items.map( (item, index) => (
		<div key={index}>
			<div className="row align-items-stretch mb-3">
				<div className="col-md-3 py-3 border border-right-0">
					<div className="row">
						<div className="col-md-4">
							<a href={item.WebsiteLink}><img src={placeholder} className="img-fluid" alt={item.FirmName} style={{backgroundColor: '#ccc'}}/></a>
						</div>
						<div className="col-md-8">
							<p><strong>{item.FirmName}</strong><br />
							    <small className="text-info">10 of 213 lawyers</small></p>
						</div>
					</div>
				</div>
				<div className="col-md-2 py-3 border border-right-0 text-center">
					<p>
						<i className="fas fa-star text-warning"></i>
						<i className="fas fa-star text-warning"></i>
						<i className="fas fa-star text-warning"></i>
						<i className="fas fa-star text-warning"></i>
						<i className="fal fa-star text-warning"></i>
						<br />
						9.6 out of 10<br />
						(942 reviews)
					</p>
				</div>
				<div className="col-md-1 py-3 border border-right-0 text-center"><i className="far fa-2x fa-check-circle text-success"></i></div>
				<div className="col-md-2 py-3 border border-right-0 text-center">
					<i className="fal fa-map-marker-alt text-primary"></i> {item.DistanceMiles.toFixed(1)} miles
				</div>
				<div className="col-md-3 py-3 border border-right-0 text-center">
					<HeadlineFee fees={item} />
				</div>
				<div className="col-md-1 py-3 border">
					<Button color="primary" onClick={() => alert('click')} block>More</Button>
				</div>
			</div>
			<Collapse isOpen={false}>
				<div className="row align-items-center mt-2 mb-4 py-4">
					<div className="col-md" id="lawFirmDetail">
						<p><strong>{item.SraId}</strong></p>
						<p><strong>{item.Address}</strong></p>
						<p><strong>{item.TelephoneNumber}</strong></p>
						<p><strong>{item.WebsiteLink}</strong></p>
					</div>
					<div className="col-md">
						<p><strong>Fee Breakdown</strong></p>
						<FeeBreakdown data={item.FeeBreakdown} />
					</div>
				</div>
			</Collapse>
		</div>
	))

	if (props.data.error) {
		return (
			<div className="container">
				<ErrorHandler error={props.data.error} />
			</div>
		)
	} else if (!props.data.isLoaded && props.data.loading) {
		return (
			<div className="text-center py-5 mt-5">
				<ResultsLoader color="success" size="5" data={props.data} />
			</div>
		)
	} else {
		return (
			<div className="container">{items}</div>
		)
	}
}

const HeadlineFee = (props) => {

	if(!props.fees.TotalFeeMin && !props.fees.TotalFeeMax) {
		return null
	}

	return (
		<div>
			<p className="lead">&pound;{props.fees.TotalFeeMin.toFixed(2)} - &pound;{props.fees.TotalFeeMax.toFixed(2)}</p>
			<Collapse isOpen={false}></Collapse>
		</div>
	)
}

const FeeBreakdown = (props) => {
	
	if(!props.data) {
		return null;
	}
	
	const fees = props.data.map( (item, index) => (
		<div key={index}>
			<p>{item.Name}: &pound;{item.Min} to &pound;{item.Max} / Fixed fee: &pound;{item.Fix}</p>
		</div>
	))

	return fees;

}

export default Search;