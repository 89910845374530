import React from 'react';
import styles from'./Home.module.scss';
import Search from './Search';
import { PageClass } from './PageClass';

PageClass('Home');

class Home extends React.Component {
	render() {
		return (
			<div className={styles.background}>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 pt-5 mt-5">
							<h1>Find the best priced<br /> legal services in<br/> your area.</h1>
							<Search />
						</div>
						<div className="col-lg-8 pt-5 mt-5"></div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;