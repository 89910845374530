import React from 'react';

export function PageClass (props) {
	return (
		<div className={props.className}></div>
	)
}

// export function renderPageClass(className) {
// 	return (
// 		<PageClass className={className} />
// 	)
// }