import React, { Component } from 'react';
import styles from'./Login.module.scss';
import logo from '../assets/images/logo.svg';
const brandname = 'Compare The Lawyer';

class Login extends Component {
	render() {
		return (			
			<div className="container-fluid">
				<div className="row">
					<div className={`col-lg-6 d-none d-lg-flex ${styles.background} ${styles.bgGradient}`}>
						<div className="row align-self-center">
							<div className="col-md-8 offset-md-2">
								<h1>Are you a law firm or lawyer looking for the most comprehensive lead generation in the legal industry?</h1>
								<p className={styles.subHeading}>GET STARTED FROM JUST £9.99 P/M*</p>
							</div>
						</div>
					</div>
					<div className={`col-lg-6 d-lg-flex bg-white ${styles.background}`}>
						<div className="row align-self-center w-100">
							<div className="col-md-8 offset-md-2">
								<img src={logo} className="App-logo" alt={brandname} />
								<p className="text-center">For legal professionals</p>
								<form>
									<div className="form-group">
										<input type="email" className="form-control" placeholder="Enter email" />
									</div>
									<div className="form-group">
										<input type="password" className="form-control" placeholder="Password" />
									</div>
									<div className="form-group form-check">
										<input type="checkbox" className="form-check-input" />
										<label className="form-check-label">Remember me</label>
									</div>
									<button type="submit" className="btn btn-primary">Login</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;