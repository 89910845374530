import React from 'react';
import PageBanner from './PageBanner';
import './PageAbout.scss';

function PageAbout() {
	return (
		<PageBanner pagename="About" strap="Test" />
	)
}

export default PageAbout;