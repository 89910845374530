import React from 'react';
import './PageBanner.scss';

function Banner(props) {
	return (
		<div className="banner d-flex align-items-center mb-5">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 mx-auto text-center">
						<h1>{props.pagename}</h1>
						<p>{props.strap}</p>
					</div>
				</div>
			</div>
		</div>
	)
} 

export default Banner;