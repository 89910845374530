import React, { Component } from "react";
import Autosuggest from 'react-autosuggest';
import Axios from 'axios';

import './AutoPostcode.scss';

/* ---------- */
/*    Data    */
/* ---------- */

function getMatchingLanguages(value, data) {
	const escapedValue = escapeRegexCharacters(value.trim());
	if (escapedValue === '') {
		return [];
	}

	const regex = new RegExp('^' + escapedValue, 'i');
	return data.filter(location => regex.test(location.wardname));
}


/* ----------- */
/*    Utils    */
/* ----------- */

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/* --------------- */
/*    Component    */
/* --------------- */

function getSuggestionValue(suggestion) {
	return suggestion.postcode;
}

function shouldRenderSuggestions(suggestion) {
	return suggestion.trim().length > 3;
  }

function renderSuggestion(suggestion) {
	return (
		<span>{suggestion.wardname}, {suggestion.localauthorityname} - <small>{suggestion.postcode}</small></span>
	);
}

class AutoPostcode extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: '',
			suggestions: [],
			isLoading: false
		};

		this.lastRequestId = null;
	}

	loadSuggestions(value) {
		// Cancel the previous request
		if (this.lastRequestId !== null) {
			clearTimeout(this.lastRequestId);
		}

		this.setState({
			isLoading: true
		});

		

		// Fake request
		// this.lastRequestId = (() => {

			Axios.get( process.env.REACT_APP_APISRV + 'getpostcodes?q=' + value )
			.then(
				response => {
					this.setState({
						isLoading: false,
						suggestions: getMatchingLanguages(value, response.data)
					});
				},
				error => {
					this.setState({
						isLoading: false,
						error
					});
				}
			)

		// }
	}

	onChange = (event, { newValue }) => {
		this.setState({
			value: newValue
		});
		this.props.locationInput(newValue);
	};

	onSuggestionsFetchRequested = ({ value }) => {
		this.loadSuggestions(value);
	};

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};

	Status = (isLoading) => {
		if (isLoading) {
			return <i className={`far fa-circle-notch fa-spin text-primary`}></i>
		}
		return null;
	}

	render() {
		const { value, suggestions, isLoading } = this.state;
		const inputProps = {
			placeholder: "Enter location",
			value,
			onChange: this.onChange,
			className: 'form-control'
		};
		
		return (
			<div className="d-flex">
				<Autosuggest
					suggestions={suggestions}
					highlightFirstSuggestion={true}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					getSuggestionValue={getSuggestionValue}
					renderSuggestion={renderSuggestion}
					inputProps={inputProps}
					shouldRenderSuggestions={shouldRenderSuggestions} /> 
				<div>
					{this.Status(isLoading)}
				</div>
			</div>
		);
	}
}

export default AutoPostcode;