import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Home, PageAbout, Search, Services, Login } from './Pages';
import './App.scss';
import logo from '../assets/images/logo.svg';
const brandname = 'Compare The Lawyer';

class App extends Component {

	render () {
		return (
			<Router>
				<Header />
					<main className="App-Main">
						<Route path="/" exact component={ Home } />
						<Route path="/about/" component={ PageAbout } />
						<Route path="/search/" component={ Search } />
						<Route path="/service/" component={ Services } />
						<Route path="/signin/" component={ Login } />
					</main>
				<Footer />
			</Router>
		)
	}
}

function Header() {
	return (
		<header className="App-header">
			<nav className="navbar navbar-expand-md">
				<Link className="navbar-brand" to={{ pathname: "/" }}>
					<img src={logo} className="App-logo" alt={brandname} />
				</Link>
				<ul className="navbar-nav ml-auto">
					<li className="nav-item active">
						<Link className="nav-link" to={{ pathname: "/" }}>Home <i className="fal fa-fw fa-chevron-down"></i></Link>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to="/about/">About <i className="fal fa-fw fa-chevron-down"></i></Link>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to="/search/">Search <i className="fal fa-fw fa-chevron-down"></i></Link>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to="/property/">Property <i className="fal fa-fw fa-chevron-down"></i></Link>
					</li>
					<li className="nav-item">
						<Link className="btn btn-primary" to="/signin/">Login</Link>
					</li>
					<li className="nav-item">
						<Link className="btn btn-primary" to="/signin/legal/">For Lawyers</Link>
					</li>
				</ul>
			</nav>
		</header>
	)
}

function Footer() {

	var timeNow = new Date();
	var year = timeNow.getFullYear();

	return (
		<footer className="App-footer">
			&copy; {brandname} {year}
		</footer>	
	)
}

export default App;